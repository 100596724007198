<div id="root">
  <div class="section Visualizer_section__1rdtY Visualizer_bodyV__167B4">
    <div class="container Visualizer_container__DXlw2">
      <div class="Visualizer_bg__2hH0h">
        <div class="Visualizer_preview__2KbwJ">
          <div class="Visualizer_frame__2EVLS"><img *ngIf="img != undefined"
            [src]="img"
            alt="MetalHeads Collection"></div>
        </div>
      </div>
      <div class="Visualizer_details__2OHTJ">
        <div class="Visualizer_mt__25yr0 Visualizer_text1__2XMYx"><b>Name:</b> Metal Heads #{{id}}</div>
        <!--<div class="Visualizer_mt__25yr0 Visualizer_text1__2XMYx"><b>ACR Rank:</b> {{acr}}</div>-->
        <div class="Visualizer_mt__25yr0 Visualizer_text1__2XMYx"><b>Owner:</b>
          <p class="Visualizer_walletAddress__35lGJ">{{owner}}</p></div>
        <div class="Visualizer_mt__25yr0 Visualizer_text1__2XMYx">
          <div *ngIf="img != undefined" class="Visualizer_containerTraits__36IbI">
            <div class="Visualizer_center__1lat3">{{data}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Visualizer_buttonContainer__KkuVb">
      <div class="Visualizer_mt__25yr0">
        <div class="">
          <div *ngIf="connected" class="TextInput_label__tC6LN">TokenID</div>
          <div *ngIf="connected"><input  (change)="idChange($event)" class="TextInput_input__ouAF8" type="number" placeholder="Enter tokenID" max="1000" min="1"></div>

        </div>
        <button *ngIf="connected" class="button Visualizer_button__3FS8a" (click)="viewNft()">View NFT</button>
        <button *ngIf="!connected" class="button Visualizer_button__3FS8a" (click)="connect()">Connect</button>

      </div>
    </div>
  </div>
</div>
