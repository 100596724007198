import {Injectable} from '@angular/core';

import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';


declare let require: any;
declare let window: any;
let web3: any;
const tokenAbi = require('./abi.json');
//const acr = require('');

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor() {
   ContractService.getWebProvider();
  }

  public async tokenUri(tokenId) {
    const contract = await ContractService.getContract(true);

    return await contract['tokenURI'](tokenId);
  }

  public async totalSupply() {
    const contract = await ContractService.getContract(true);

    return  await contract['totalSupply']();
  }

  public async owner(tokenId) {
    const contract = await ContractService.getContract(true);

    return await contract['ownerOf'](tokenId);
  }

  private static async getContract(bySigner= false) {
    const provider = await this.getWebProvider();
    const signer = provider.getSigner();

    return new ethers.Contract(
      '0x03eDe736Ff083C2e87014b73DcD703a1520e23Bc',
      tokenAbi,
      bySigner ? signer : provider,
    );
  }

  // tslint:disable-next-line:typedef
  private static async getWebProvider(requestAccounts = true) {
    const provider: any = await detectEthereumProvider();

    console.log(provider);
    if (requestAccounts) {
      await provider.request({ method: 'eth_requestAccounts' });
    }

    return new ethers.providers.Web3Provider(provider);
  }

}

export class Attribute {
  rarity?: number;
  value?: string;

  constructor(rariry?: number, value?: string) {
    this.rarity = rariry;
    this.value = value;
  }
}

export class Grungie {
  id?: number;
  dna?: string;
  acr?: number;
  imageUrl: string;

  background: Attribute;
  nose: Attribute;
  eyebrow: Attribute;
  eyes: Attribute;
  glasses: Attribute;
  hair: Attribute;
  beard: Attribute;
  mouth: Attribute;
  clothes: Attribute;
}
