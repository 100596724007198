import {Component, OnInit} from '@angular/core';
import {Attribute, ContractService, Grungie} from './contract.service';
import {HttpClient} from '@angular/common/http';

//import acr from './acr.json';
//const acr = require('./acr.json');
import {error} from 'protractor';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'visualizer';
  img: string;

  idToView: number;

  id: number;
  na: string;
  acr: number;
  imageUrl: string;

  background: Attribute;
  nose: Attribute;
  eyebrow: Attribute;
  eyes: Attribute;
  glasses: Attribute;
  hair: Attribute;
  beard: Attribute;
  mouth: Attribute;
  clothes: Attribute;
  data: string;

  connected: boolean = false;

  owner: string;

  constructor(private contractService: ContractService, private http: HttpClient) {
    this.connect();
  }

  public idChange(event) {
    console.log(event.target.value);
    this.idToView = event.target.value;
    this.id = event.target.value;
  }

  public viewNft(): void {
    console.log(this.idToView);
    this.id = this.idToView;
    this.contractService.tokenUri(this.idToView).then(r => {
      console.log(r);
      const jsonUrl = r.replace('ipfs://', 'https://ipfs.io/ipfs/');

      console.log(jsonUrl);
      this.http.get(jsonUrl).subscribe(data => {
        console.log(data);
        this.data = JSON.stringify(data, null, 3);

        this.img = data['image'].replace("ipfs://", "https://ipfs.io/ipfs/");
        if (data['edition']) {
          this.id = data['edition'];
        }

        this.contractService.owner(this.idToView).then(ow => {
          this.owner = ow;
        });

        // alert('Token not found, may be not minted yet !');

      }, error => {
        this.img = undefined;
        this.owner = undefined;

        alert('Token not found, may be not minted yet !');
      });


    }, error => {
      this.img = undefined;
      this.owner = undefined;
      alert('Token not found, may be not minted yet !');

    });
  }

  ngOnInit(): void {
    console.log('ngOninit()');
  }

  connect(): void {

    this.contractService.totalSupply().then(ts => {
      console.log("connected");
      this.connected = true;
    }, error => {
      console.log("non connecte");
    });
  }
}



